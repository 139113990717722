<template>
  <div class="Hotel">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="酒店信息" name="first">
        <el-card class="box-card">
          <el-button type="primary" style="height: 40px" @click="addHotel" plain
            >新增酒店</el-button
          >
          <div class="main-card">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="550"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
            >
              <el-table-column label="酒店名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="省">
                <template slot-scope="scope">
                  <span>{{ scope.row.province }}</span>
                </template>
              </el-table-column>
              <el-table-column label="城市">
                <template slot-scope="scope">
                  <span>{{ scope.row.city }}</span>
                </template>
              </el-table-column>
              <el-table-column label="酒店地址">
                <template slot-scope="scope">
                  <span>{{ scope.row.address }}</span>
                </template>
              </el-table-column>
              <el-table-column label="酒店电话">
                <template slot-scope="scope">
                  <span>{{ scope.row.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="最早入住日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.earliestCheckinDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="最晚退房日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.latestCheckoutDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="editHotel(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    @click="handleRoomType(scope.$index, scope.row)"
                    >房型</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="handledelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="page-card">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="预订信息" name="second">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="btn1">
              <span style="height: 40px; line-height: 40px">酒店：</span>
              <el-input
                style="margin-right: 20px; width: 200px"
                v-model="hotelName"
              ></el-input>
              <span style="height: 40px; line-height: 40px">联系人：</span>
              <el-input
                style="margin-right: 20px; width: 200px"
                v-model="contactUser"
              ></el-input>
              <span style="height: 40px; line-height: 40px">联系电话：</span>
              <el-input
                style="margin-right: 20px; width: 200px"
                v-model="phone"
              ></el-input>
              <!--<el-col :span="4">-->
              <!--<el-date-picker-->
              <!--type="date"-->
              <!--placeholder="选择日期"-->
              <!--v-model="runDate"-->
              <!--format="yyyy-MM-dd"-->
              <!--value-format="yyyy-MM-dd"-->
              <!--style="width: 100%"-->
              <!--&gt;</el-date-picker>-->
              <!--</el-col>-->
              <el-button
                style="height: 40px; margin-left: 10px"
                type="primary"
                plain
                @click="handleSearchTwo"
                >查询</el-button
              >
              <el-button
                style="height: 40px"
                type="primary"
                plain
                @click="handleExport"
                >导出预订信息</el-button
              >
            </div>
          </div>
          <div class="main-card">
            <el-table
              :data="ReserveTableData"
              style="width: 100%"
              height="540"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
            >
              <el-table-column label="代表队">
                <template slot-scope="scope">
                  <span>{{ scope.row.teamName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="负责人">
                <template slot-scope="scope">
                  <span>{{ scope.row.contactUser }}</span>
                </template>
              </el-table-column>
              <el-table-column label="联系电话">
                <template slot-scope="scope">
                  <span>{{ scope.row.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="酒店">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="房型">
                <template slot-scope="scope">
                  <span>{{ scope.row.roomName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="日期">
                <template slot-scope="scope">
                  <span>{{ bookingDays(scope.row.bookingDays) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="入住人">
                <template slot-scope="scope">
                  <span>{{ scope.row.bookUser.join(",") }}</span>
                </template>
              </el-table-column>
              <el-table-column label="单价">
                <template slot-scope="scope">
                  <span>{{ scope.row.price / 100 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="间数">
                <template slot-scope="scope">
                  <span>{{ scope.row.bookingNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  <span>{{ scope.row.payMoney / 100 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="预订时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.bookTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="支付时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.payTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="预订状态">
                <template slot-scope="scope">
                  <span>{{ statusList[scope.row.status] }}</span>
                  <!-- <span v-if="scope.row.status == 0">待支付</span>
                  <span v-if="scope.row.status == 1">支付成功(废弃)</span>
                  <span v-if="scope.row.status == 2">支付成功待酒店确认</span>
                  <span v-if="scope.row.status == 3">预定成功</span>
                  <span v-if="scope.row.status == 4">订单取消</span>
                  <span v-if="scope.row.status == 5">退款中</span>
                  <span v-if="scope.row.status == 6">已退款</span> -->
                </template>
              </el-table-column>
              <el-table-column label="退款入住人">
                <template slot-scope="scope">
                  <span>{{ scope.row.refundUsers }}</span>
                </template>
              </el-table-column>
              <el-table-column label="退款总金额">
                <template slot-scope="scope">
                  <span>{{ scope.row.refundMoney / 100 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.status == 2"
                    type="danger"
                    size="mini"
                    @click="okInfo(scope.row)"
                    >确认</el-button
                  >
                  <el-button
                    v-if="scope.row.status != 4 && scope.row.status >= 2"
                    type="danger"
                    size="mini"
                    @click="refundMoney(scope.row)"
                    >退款</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page-card">
            <el-pagination
              @size-change="handleSizeChangeTwo"
              @current-change="handleCurrentChangeTwo"
              :current-page="currentPageTwo"
              :page-size="pageSizeTwo"
              layout="total, sizes, prev, pager, next, jumper"
              :total="infoTotalCount"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="退款申请"
      :visible.sync="dialogRefund"
      @close="dialogClose"
      class="refund"
      center
    >
      <el-form :model="refundForm" ref="refundForm">
        <div class="two" style="position: relative">
          <el-form-item label="入住人" :label-width="formLabelWidth">
            <el-radio-group v-model="userRecordIds" @change="changeClick">
              <el-radio
                v-for="(item, index) in personType"
                :key="index"
                :label="item.id"
                :disabled="item.status != 0"
                >{{ item.bookUser }}
                <el-tag
                  :type="
                    item.status == '0'
                      ? 'success'
                      : item.status == '1'
                      ? 'info'
                      : ''
                  "
                  disable-transitions
                  >{{
                    item.status == "0"
                      ? "预订成功"
                      : item.stastu == "1"
                      ? "退款中"
                      : "已退款"
                  }}</el-tag
                >
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="入住时间"
            :label-width="formLabelWidth"
            v-if="showTime == true"
          >
            <el-checkbox-group v-model="days">
              <el-checkbox
                v-for="(item, index) in checkTimeList"
                :key="index"
                :label="item.time"
              >
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="退款金额:" :label-width="formLabelWidth">
            <el-input
              style="width: 200px"
              v-model="refundForm.amount"
              placeholder="请输入退款金额"
              autocomplete="off"
            ></el-input
            >元
          </el-form-item>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <el-button @click="dialogRefund = false">取 消</el-button>
          <el-button type="primary" @click="confirmRefund">确认退款</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="hasEdit ? '编辑酒店' : '新增酒店'"
      :visible.sync="dialogAddVisible"
      center
      class="hotel"
      :close-on-click-modal="false"
      @close="hotelClose"
    >
      <el-steps
        :active="active"
        finish-status="success"
        style="margin-bottom: 50px"
      >
        <el-step title="酒店信息"> </el-step>
        <el-step title="信息补充"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <el-form :model="form" ref="addForm" resetFields>
        <div class="one" v-if="active === 1">
          <el-form-item
            label="酒店照片"
            :label-width="formLabelWidth"
            prop="imageId"
          >
            <el-upload
              class="upload-demo"
              action=""
              :before-remove="beforeRemove"
              :on-change="getFileEdit"
              :http-request="uploadImg"
              :limit="1"
              :on-exceed="handleExceed"
              list-type="picture-card"
              :auto-upload="false"
              :show-file-list="false"
            >
              <img v-if="coverUrl" :src="coverUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <!--<el-button size="small" type="primary" plain>点击上传</el-button>-->
            </el-upload>
          </el-form-item>
          <el-form-item
            label="酒店名称"
            :label-width="formLabelWidth"
            prop="name"
            :rules="[
              { required: true, message: '请输入酒店名称', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="form.name"
              placeholder="请输入酒店名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="省市"
            :label-width="formLabelWidth"
            prop="province"
            :rules="[
              { required: true, message: '请选择省份', trigger: 'blur' },
            ]"
          >
            <el-select
              v-model="form.province"
              placeholder="请选择省份（直辖市）"
              @change="renderProvince"
              @focus="renderCity"
              style="margin-right: 10px"
            >
              <el-option
                v-for="item in provinceListAll"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.city"
              placeholder="请选择城市"
              @change="handleChange"
            >
              <el-option
                v-for="citys in cityListAll"
                :key="citys.value"
                :label="citys.label"
                :value="citys.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="酒店所在区域"
            :label-width="formLabelWidth"
            prop="areaName"
            :rules="[
              {
                required: true,
                message: '请输入酒店所在区域',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.areaName"
              placeholder="请输入酒店所在区域"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="酒店详细地址"
            :label-width="formLabelWidth"
            prop="address"
            :rules="[
              {
                required: true,
                message: '请输入酒店详细地址',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.address"
              placeholder="请输入酒店详细地址"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="酒店描述"
            :label-width="formLabelWidth"
            prop="descriptions"
            :rules="[
              {
                required: true,
                message: '请输入酒店描述',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="textarea"
              v-model="form.descriptions"
              placeholder="请输入酒店描述"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="酒店电话"
            :label-width="formLabelWidth"
            prop="phone"
            :rules="[
              {
                required: true,
                message: '请输入酒店电话',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.phone"
              placeholder="请输入酒店电话"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="最早入住日期"
                :label-width="formLabelWidth"
                prop="earliestCheckinDate"
                :rules="[
                  {
                    required: true,
                    message: '请选择最早入住日期',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.earliestCheckinDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item
                label="最晚退房日期"
                :label-width="formLabelWidth"
                prop="latestCheckoutDate"
                :rules="[
                  {
                    required: true,
                    message: '请选择最晚退房日期',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.latestCheckoutDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker> </el-form-item
            ></el-col>
          </el-row>

          <el-form-item
            label="备注"
            :label-width="formLabelWidth"
            prop="remarks"
            :rules="[
              {
                required: true,
                message: '请输入备注',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.remarks"
              placeholder="请输入备注"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>
        <div class="two" v-if="active === 2" style="position: relative">
          <div class="star">*</div>
          <el-form-item label="订房须知:" :label-width="formLabelWidth">
          </el-form-item>
          <el-form-item
            :label-width="formLabelWidth"
            v-for="(item, index) in notice"
            :key="index"
          >
            <el-input v-model="item.name" style="width: 75%"></el-input>
            <el-button
              type="primary"
              @click="addInput"
              style="margin-left: 5px"
              icon="el-icon-plus"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-minus"
              v-if="index != 0"
              @click="delInput(index)"
            ></el-button>
          </el-form-item>
          <el-form-item label="酒店政策:" :label-width="formLabelWidth">
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="入住时间:" :label-width="formLabelWidth2">
                <el-input
                  v-model="hotelPolicy.inTime"
                  placeholder="例:10:00以后"
                /> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="离店时间:" :label-width="formLabelWidth2">
                <el-input
                  v-model="hotelPolicy.outTime"
                  placeholder="例:12:00以后"
                /> </el-form-item
            ></el-col>
          </el-row>

          <el-form-item label="押金金额:" :label-width="formLabelWidth2">
            <el-input
              type="number"
              v-model="hotelPolicy.deposit"
              style="width: 200px"
              placeholder="请输入押金金额"
            />元
          </el-form-item>
          <el-form-item label="接受人群:" :label-width="formLabelWidth2">
            <el-input
              v-model="hotelPolicy.reception"
              placeholder="例：全球客人"
            />
          </el-form-item>
          <el-form-item label="加床政策:" :label-width="formLabelWidth2">
            <el-input
              v-model="hotelPolicy.bed"
              placeholder="例：支持收费加床200元"
            />
          </el-form-item>
          <el-form-item label="儿童政策:" :label-width="formLabelWidth2">
            <el-input
              v-model="hotelPolicy.children"
              placeholder="例：酒店允许携带儿童入住"
            />
          </el-form-item>
        </div>
        <div class="three" v-if="active === 3" style="text-align: center">
          <img src="../../assets/200.png" style="margin-top: 20px" alt="" />
          <div>添加成功</div>

          <el-button type="primary" @click="good" style="width: 100px"
            >好的</el-button
          >
        </div>
        <div class="bottom_button">
          <el-button type="primary" v-if="active < 2" @click="onStep(1)"
            >下一页</el-button
          >
          <el-button v-if="active == 2" @click="onStep(-1)">上一页</el-button>
          <el-button
            v-if="active == 2"
            type="primary"
            @click="pushNewHotel"
            style="width: 100px"
            >提交</el-button
          >
        </div>
      </el-form>
      <!-- <div v-if="active == 2" slot="footer" class="dialog-footer">
      
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data"; // 4.省市区带‘全部’三级联动选择
// import axios from 'axios'
import moment from "moment";
// import CompanyTabsVue from '../views/Sports/CompanyTabs.vue'
export default {
  data() {
    return {
      nav: this.$route.params.back,
      active: 1,
      showTime: false,
      notice: [{}],
      hotelPolicy: {
        inTime: "",
        outTime: "",
        deposit: "",
        reception: "",
        bed: "",
        children: "",
      },
      hasEdit: false,
      fileList: [],
      stepKey: 1,
      id: this.$route.params.id,
      total: 10.0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      totalTwo: 10.0,
      currentPageTwo: 1,
      pageSizeTwo: 10,
      infoTotalCount: 0,
      playerName: "",
      groupCodeName: "",
      tableData: [],
      ReserveTableData: [],

      copyRoomType: "",
      dialogAddVisible: false,
      dialogRefund: false,
      dialogEditVisible: false,
      dialogReserveVisible: false,
      dialogRoomVisible: false,
      dialogAddRoomVisible: false,
      form: {
        province: "",
        city: "",
      },
      editForm: {
        province: "",
        city: "",
      },
      refundForm: {
        amount: "",
        type: [],
      },
      userRecordIds: [],
      days: [],
      runDate: "",
      provinceListAll: provinceAndCityData,
      cityListAll: {},
      formLabelWidth: "120px",
      formLabelWidth1: "60px",
      formLabelWidth2: "150px",
      provinceSelectAble: false,
      hotelList: [],
      hotelName: "",
      contactUser: "", // 联系人搜索用
      phone: "", // 联系电话
      unitList: [],
      unitName: "",
      fileName: "",
      file: {},
      coverUrl: "",
      path: "",
      urlList: [],
      fileLists: [],
      rules: {
        name: [{ required: true, message: "请输入酒店名称", trigger: "blur" }],
        province: [{ required: true, message: "请选择省份", trigger: "blur" }],
        city: [{ required: true, message: "请选择城市", trigger: "blur" }],
        address: [
          { required: true, message: "请输入酒店地址", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入酒店电话", trigger: "blur" }],
        description: [
          { required: true, message: "请输入酒店描述", trigger: "blur" },
        ],
        earliestCheckinDate: [
          { required: true, message: "请选择最早入住日期", trigger: "blur" },
        ],
        latestCheckoutDate: [
          { required: true, message: "请选择最晚退房日期", trigger: "blur" },
        ],
        remarks: [{ required: true, message: "请输入备注", trigger: "blur" }],
        roomType: [{ required: true, message: "请输入房型", trigger: "blur" }],
        price: [{ required: true, message: "请输入单价", trigger: "blur" }],
        effectiveDate: [
          { required: true, message: "请选择日期", trigger: "blur" },
        ],
        totalNum: [
          { required: true, message: "请选择总房间数", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请选输入排序", trigger: "blur" }],
      },
      roomFileName: "",
      roomFile: "",
      roomPath: "",
      roomUrlList: [],
      activeName: "first", //tab默认页
      bookingInfoId: "",
      personType: "",
      timeType: "",
      bookingId: "",
      statusInfo: "",
      defaultDataPicker: "",
      statusList: {
        0: "待支付",
        1: "支付成功(废弃)",
        2: "支付成功待酒店确认",
        3: "预定成功",
        4: "订单取消",
        5: "退款中",
        6: "已退款",
      },
      checkTimeList: "",
    };
  },
  created() {
    this.getTableData();
    // this.handleSearch();
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({ name: "Home" });
    },
    handleClick(tab) {
      if (tab.name == "first") {
        this.getTableData();
      } else if (tab.name == "second") {
        this.handleSearch();
      }
    },
    handleChange() {
      this.$forceUpdate();
    },
    good() {
      this.fileList = [];
      this.form = {};
      this.dialogAddVisible = false;
      this.active = 1;
    },
    hotelClose() {
      this.$refs.addForm.clearValidate();
      this.form = {};
      this.hotelPolicy = {
        inTime: "",
        outTime: "",
        deposit: "",
        bed: "",
        reception: "",
        children: "",
      };
      this.notice = [{}];
    },
    dialogClose() {
      this.userRecordIds = [];
      this.days = [];
      this.refundForm.amount = "";
      this.showTime = false;
    },
    onStep(value) {
      if (this.form.earliestCheckinDate > this.form.latestCheckoutDate) {
        this.$message({
          type: "warning",
          message: "最晚入住时间不能早于最早入住时间",
        });
        return;
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const resultValue = this.active + value;
          if (resultValue === 3) {
            this.pushNewHotel();
            return;
          }
          if (resultValue > 0 && resultValue < 4) {
            this.active = resultValue;
          } else if (this.active == 2) {
            this.pushNewHotel();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加input
    addInput() {
      this.notice.push({ name: "" });
    },
    delInput(index) {
      this.notice = this.notice.filter((item, itemIndex) => {
        return index !== itemIndex;
      });
    },
    //查询酒店的房型
    handleRoomType(index, row) {
      console.log(row);
      this.$router.push({
        name: "HotelRoomType",
        params: {
          back: row.name,
          id: this.id,
          hotelId: row.id,
          name: "1",
          eDate: row.earliestCheckinDate,
          lDate: row.latestCheckoutDate,
          pageSize: this.$route.params.pageSize,
          currentPage: this.$route.params.currentPage,
          pageSizeTwo: this.$route.params.pageSizeTwo,
          currentPageTwo: this.$route.params.currentPageTwo,
        },
      });
    },

    //获取所有酒店信息
    async getTableData() {
      try {
        const result = await this.$api.hotelData(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    changeClick(v) {
      this.showTime = true;
      this.checkTimeList = this.personType.filter(
        (item) => item.id === v
      )[0].refundDayList;
      console.log(this.checkTimeList, "过滤数组");
    },
    //获取退款入住人
    async getPerson() {
      try {
        const result = await this.$api.getBookUserList(
          `bookingInfoId=${this.bookingInfoId}`
        );
        this.personType = result;
        console.log(this.personType.data.refundDayList, "是什么");
      } catch (error) {
        console.log(error);
      }
    },

    async confirmRefund() {
      console.log(this.days, "aaaaa");
      try {
        const result = await this.$api.saveHotelRefundRecord(
          `bookingInfoId=${this.bookingInfoId}&userRecordId=${JSON.stringify(
            this.userRecordIds
          )}&amount=${this.refundForm.amount * 100}&days=${JSON.stringify(
            this.days
          )}`
        );
        if (result.data.status_code == "200") {
          this.$message({
            type: "success",
            message: "退款申请成功",
          });
          this.handleSearch();
          this.dialogRefund = false;
        } else if (!this.userRecordIds.length) {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        } else {
          this.$message({
            type: "warning",
            message: result.data.msg,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    //酒店预定信息列表
    async handleSearch() {
      try {
        const result = await this.$api.hotelReserve(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPageTwo}&pageSize=${this.pageSizeTwo}&search_LIKE_hotelName=${this.hotelName}&search_EQ_contactUser=${this.contactUser}&search_EQ_phone=${this.phone}`
        );
        this.ReserveTableData = result.pageResults;
        this.infoTotalCount = result.totalCount;
        this.ReserveTableData.forEach((item) => {
          item.bookUser = JSON.parse(item.bookUser);
        });
        this.bookingInfoId = this.ReserveTableData.map((item) => {
          return item.id;
        });
        console.log(this.bookingInfoId.status, "11111");
      } catch (error) {
        console.log(error);
      }
    },
    //搜索预定信息列表
    async handleSearchTwo() {
      this.currentPageTwo = 1;
      this.pageSizeTwo = 10;
      try {
        const result = await this.$api.hotelReserve(
          `search_EQ_competitionId=${this.id}&currentPage=${this.currentPageTwo}&pageSize=${this.pageSizeTwo}&search_LIKE_hotelName=${this.hotelName}&search_EQ_contactUser=${this.contactUser}&search_EQ_phone=${this.phone}`
        );
        this.ReserveTableData = result.pageResults;
        this.infoTotalCount = result.totalCount;
        this.ReserveTableData.forEach((item) => {
          item.bookUser = JSON.parse(item.bookUser);
        });
        this.bookingInfoId = this.ReserveTableData.map((item) => {
          return item.id;
        });
      } catch (error) {
        console.log(error);
      }
    },
    //提交新增酒店信息
    pushNewHotel() {
      if (Object.keys(this.notice[0]).length === 0) {
        this.$message({
          type: "warning",
          message: "至少输入一项订房需知",
        });
        return;
      }
      console.log(this.notice, "---result form");

      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          this.form.earliestCheckinDate = `${this.form.earliestCheckinDate} 00:00:00`;
          this.form.latestCheckoutDate = `${this.form.latestCheckoutDate} 23:59:59`;
          this.form.coverUrl = this.coverUrl;
          this.form.competitionId = this.id;

          this.form.notice = JSON.stringify(
            this.notice.map((value) => value.name)
          );
          this.form.hotelPolicy = JSON.stringify({
            inTime: this.hotelPolicy.inTime,
            outTime: this.hotelPolicy.outTime,
            children: this.hotelPolicy.children,
            bed: this.hotelPolicy.bed,
            reception: this.hotelPolicy.reception,
            deposit: this.hotelPolicy.deposit,
          });
          try {
            const api = this.hasEdit ? this.$api.hotelEdit : this.$api.hotelAdd;
            const result = await api(this.form);
            if (result.data.status_code == "200") {
              this.active = 3;
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //退款
    refundMoney(row) {
      this.bookingInfoId = row.id;
      this.dialogRefund = true;
      this.getPerson();
    },
    //时间
    bookingDays(value) {
      if (!value) return "";
      let arr = JSON.parse(value);
      return arr.map((val) => val.time).join(",");
    },
    //确认信息
    async okInfo(row) {
      this.bookingId = row.id;
      this.statusInfo = row.status;
      try {
        const result = await this.$api.confirmBookingInfo(
          `bookingId=${this.bookingId}`
        );
        if (result.data.status_code == "200") {
          this.handleSearch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //导出酒店预定信息
    async handleExport() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportReserve(
          `search_EQ_competitionId=${this.id}&search_LIKE_hotelName=${this.hotelName}&search_EQ_contactUser=${this.contactUser}&search_EQ_phone=${this.phone}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          // window.location.href = objectUrl
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //删除酒店信息
    handledelete(index, row) {
      this.$confirm("此操作将永久删除酒店, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.hotelDelete(`hotelId=${row.id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTableData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    //新增酒店按钮
    addHotel() {
      this.coverUrl = "";
      this.form = {};
      this.required = false;
      this.dialogAddVisible = true;
      this.hasEdit = false;
      this.active = 1;
    },
    //编辑酒店前的信息处理
    editHotel(index, row) {
      console.log(row, "editForm");
      this.form = {
        id: row.id,
        name: row.name,
        province: row.province,
        city: row.city,
        address: row.address,
        areaName: row.areaName,
        phone: row.phone,
        descriptions: row.descriptions,
        remarks: row.remarks,
        coverUrl: row.coverUrl,
        notice: "",
        hotelPolicy: "",
        inventoryLimit: row.inventoryLimit,
        earliestCheckinDate: row.earliestCheckinDate,
        latestCheckoutDate: row.latestCheckoutDate,
      };
      this.coverUrl = row.coverUrl;
      this.form.earliestCheckinDate = moment(
        row.earliestCheckinDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD");
      this.form.latestCheckoutDate = moment(
        row.latestCheckoutDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD");
      this.active = 1;
      this.hotelPolicy = JSON.parse(row.hotelPolicy);
      this.notice = JSON.parse(row.notice).map((value) => ({ name: value }));
      this.dialogAddVisible = true;
      console.log("form", this.form);
      this.hasEdit = true;
      this.renderProvince();
    },
    //酒店预定信息按钮
    reserveInfo() {
      this.dialogReserveVisible = true;
      this.handleSearch();
      // this.getHotelDrop()
      // this.getUnitDrop()
    },
    //获取省份后的处理(新增)
    renderProvince() {
      // this.provinceSelectAble = true;
      this.cityListAll = this.provinceListAll.filter((item) => {
        return item.label == this.form.province;
      })[0].children;
      // console.log(this.cityListAll)
    },
    //省份选择框聚焦后立刻清除市的信息(新增)
    renderCity() {
      this.form.city = "";
    },
    //获取省份后的处理(编辑)
    renderProvinceEdit() {
      // this.provinceSelectAble = true;
      this.cityListAll = this.provinceListAll.filter((item) => {
        return item.label == this.editForm.province;
      })[0].children;
      // console.log(this.cityListAll)
    },
    //省份选择框聚焦后立刻清除市的信息(编辑)
    renderCityEdit() {
      this.editForm.city = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChangeTwo(val) {
      this.pageSizeTwo = val;
      this.handleSearch();
    },
    handleCurrentChangeTwo(val) {
      this.currentPageTwo = val;
      this.handleSearch();
    },
    //覆盖了upload的默认上传行文
    async uploadImg() {
      try {
        const result = await this.$api.uploadImg(`fileName=${this.fileName}`); //获取上传配置信息
        console.log(result);
        this.path = result.showDomain; //保存配置信息
        // 2、设置上传配置
        let formData = new FormData();
        formData.append("authorization", result.authorization);
        formData.append("policy", result.policy);
        formData.append("file", this.file);
        this.$http
          .post(result.commitPath, formData, {
            timeout: 3600000,
            onUploadProgress: (e) => {
              if (e.lengthComputable) {
                let rate = ((e.loaded * 100) / e.total).toFixed(2);
                console.log(rate);
                if (rate < 100) {
                }
              }
            },
          })
          .then((res) => {
            // this.urlList.push(res.data.url); //获取单张图片的url后进行数组形式的存储
            this.coverUrl = result.showDomain + res.data.url;
          });
      } catch (error) {
        console.log(error);
      }
    },

    //酒店照片添加,上传成功和上传失败时都会被调用(编辑)
    getFileEdit(file, fileList) {
      console.log("编辑状态改变了", file, fileList);
      this.fileName = file.name;
      this.file = file.raw;
      this.uploadImg();
    },
    //文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      // this.$message.warning(
      //   `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
      //     files.length + fileList.length
      //   } 个文件`
      // );
      console.log(files, fileList);
      this.$message.warning("当前只可以上传一张照片");
    },
    //移除照片前的钩子函数
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除照片吗？`);
    },
    //移除照片后的处理(新增)
    onRemove(file, fileList) {
      console.log(file, fileList);
      this.urlList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.fileName = item.name;
          this.file = item.raw;
          this.uploadImg();
        });
      }
    },
    //移除照片后的处理(编辑)
    onRemoveEdit(file, fileList) {
      console.log(file, fileList);
      for (let j = 0; j < fileList.length; j++) {
        if (fileList[j].name && fileList[j].raw) {
          this.fileName = fileList[j].name;
          this.file = fileList[j].raw;
          this.uploadImg();
        }
      }
      for (let i = 0; i < this.editForm.coverUrl.length; i++) {
        if (this.editForm.coverUrl[i] == file.url) {
          this.editForm.coverUrl.splice(i, 1);
        }
      }
      if (this.editForm.coverUrl.length == fileList.length) {
        this.urlList = [];
      }
      console.log(file, fileList);
      console.log(this.editForm.coverUrl);
      console.log(this.urlList);
    },

    async uploadRoomImg() {
      try {
        const result = await this.$api.uploadImg(
          `fileName=${this.roomFileName}&type=room`
        ); //获取上传配置信息
        console.log(result);
        this.roomPath = result.showDomain; //保存配置信息
        // 2、设置上传配置
        let formData = new FormData();
        formData.append("authorization", result.authorization);
        formData.append("policy", result.policy);
        formData.append("file", this.roomFile);
        this.$http
          .post(result.commitPath, formData, {
            timeout: 3600000,
            onUploadProgress: (e) => {
              if (e.lengthComputable) {
                let rate = ((e.loaded * 100) / e.total).toFixed(2);
                console.log(rate);
                if (rate < 100) {
                }
              }
            },
          })
          .then((res) => {
            this.roomUrlList.push(res.data.url); //获取单张图片的url后进行数组形式的存储
          });
      } catch (error) {
        console.log(error);
      }
    },
    roomBeforeRemove() {
      return this.$confirm(`确定移除照片吗？`);
    },
    onRomeRmove(file, fileList) {
      console.log(file, fileList);
      this.roomUrlList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.roomFileName = item.name;
          this.roomFile = item.raw;
          this.uploadRoomImg();
        });
      }
    },
    getFileRoom(file) {
      console.log("上传状态改变了", file);
      this.roomFileName = file.name;
      this.roomFile = file.raw;
      this.uploadRoomImg();
    },
    handleRoomExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择10个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
};
</script>

<style lang="less">
.Hotel {
  .refund {
    .el-dialog {
      min-height: 278px;
      min-width: 960px;
    }
  }
  .hotel {
    .el-dialog {
      min-height: 936px;
      min-width: 956px;
    }
  }
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn1 {
        display: flex;
        // float: right;
        .select1 {
          width: 150px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .select2 {
      width: 100px;
      margin-right: 20px;
    }
    .btn2 {
      display: flex;
      margin: 20px 0;
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
  .bottom_button {
    display: flex;
    justify-content: flex-end;
  }
}
.avatar {
  width: 92%;
  height: 92%;
  margin: 4%;
}
.star {
  color: red;
  position: absolute;
  left: 4%;
  top: 3%;
}
</style>
